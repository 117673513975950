:root {
  --primary-color: #324960;
  --third-color: #9ba6a5;
  --background-color: #f5f5f5;
  --secondary-color: #f37826;
  --bill-font-family: "Courier New", Courier, monospace;
}

body {
  background-color: var(--background-color);
}
.MaintenanceWraper {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
