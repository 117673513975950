.exportDashboard {
  font-family: "New Courier";
}

.dashboardTableHeaderWrapper {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  .dashboardTableHeader {
    width: 90%;
    font-size: 1.5rem;
    font-weight: 600;
    padding: 2rem;

    text-align: center;
    color: var(--primary-color);
    font-family: "New Courier";
    border-bottom: 2px solid #dbdbdb;
  }
}

.dashboardTable {
  margin-top: 3rem;
  width: 100%;

  .dashboardHeaderTh {
    width: 10%;
    text-align: left;
  }
  th {
    width: 7.5%;
    text-align: left;
  }
  td {
    width: fit-content;
    text-align: left !important;
    padding: 0.25rem !important;
  }
}

.dashboardWrapper {
  margin-left: 11rem;

  .dashboardHeaderWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;

    .dashboardHeader {
      width: 95%;
      text-align: center;
      color: var(--primary-color);
      padding-bottom: 1rem;
      border-bottom: 3px solid rgba($color: #dbdbdb, $alpha: 0.5);
    }
  }

  .dashboardYearSelect {
    width: 150px;
    height: 25px;
    text-align: center;
    font-size: 1rem;
    margin-bottom: 1rem;
  }

  .topChartsWrapper {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    width: 100%;

    .dashboardTableWrapper {
      width: 40%;
      margin-top: 1.25rem;

      .dashboardCustomerInfoWrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 1.5rem;

        .dashBoardPieWrapper {
          position: relative;
          height: 20rem;

          .customerInfoCardWrapper {
            padding: 0.75rem 1.5rem;
            background-color: #fff;
            width: 10rem;
            position: absolute;
            bottom: 2.5rem;
            left: 50%;
            transform: translate(-50%);

            border: 2px dashed var(--primary-color);
            text-align: center;
            color: var(--primary-color);
            font-size: 1.125rem;

            .customerInfoHeader {
              border-bottom: 2px solid var(--primary-color);
              padding-bottom: 0.25rem;
            }

            .customerInfoValue {
              margin-top: 0.5rem;
            }
          }
        }
      }
    }
  }
  .LoaderWrapper {
    top: 0;
    width: 100vw;
    left: 0;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(219, 219, 219, 0.8);
    position: absolute;
    z-index: 5;
  }
  .table-fill {
    background: white;
    border-radius: 3px;
    border-collapse: collapse;
    margin: auto;

    padding: 5px;
    width: 90%;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
    animation: float 5s infinite;

    tbody {
      display: block;
      height: 60rem;
      overflow-y: auto;
      overflow-x: hidden;
    }
    thead,
    tbody tr {
      display: table;
      width: 100%;
      table-layout: fixed; /* even columns width , fix width of table too*/
    }
    thead {
      width: calc(100% - 1em); /* scrollbar is average 1em/16px width, remove it from thead width */
    }
    table {
      width: 400px;
    }
  }

  th {
    color: #d5dde5;
    background: var(--primary-color);
    border-bottom: 4px solid #9ea7af;
    border-right: 1px solid #343a45;
    font-weight: 600;
    font-size: 1.125rem;
    padding: 0.5rem;
    text-align: center;
    text-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
    vertical-align: middle;
  }

  th:first-child {
    border-top-left-radius: 3px;
  }

  th:last-child {
    border-top-right-radius: 3px;
    border-right: none;
  }

  tr {
    border-top: 1px solid #c1c3d1;
    border-bottom-: 1px solid #c1c3d1;
    color: #666b85;
    font-size: 16px;
    font-weight: normal;
    text-shadow: 0 1px 1px rgba(256, 256, 256, 0.1);
  }

  tr:hover td {
    background: #4e5066;
    color: #ffffff;
    border-top: 1px solid #22262e;
  }

  tr:first-child {
    border-top: none;
  }

  tr:last-child {
    border-bottom: none;
  }

  tr:nth-child(odd) td {
    background: #ebebeb;
  }

  tr:nth-child(odd):hover td {
    background: #4e5066;
  }

  tr:last-child td:first-child {
    border-bottom-left-radius: 3px;
  }

  tr:last-child td:last-child {
    border-bottom-right-radius: 3px;
  }

  td {
    background: #ffffff;
    padding: 0.75rem;
    text-align: center;
    vertical-align: middle;
    font-weight: 300;
    font-size: 1rem;
    text-shadow: -1px -1px 1px rgba(0, 0, 0, 0.1);
    border-right: 1px solid #c1c3d1;
  }

  .dashboardGraphs {
    width: 60%;
    .exportBtn {
      padding: 0.5rem;
      width: 8rem;
      color: #f5f5f5;
      background-color: rgb(143, 2, 2);
      margin: 0.5rem;
      border: none;
      cursor: pointer;
      border-radius: 0.125rem;
      &:disabled {
        opacity: 0.4;
        cursor: not-allowed;
      }
    }
  }
  .customTooltipWrapper {
    background-color: #fff;
    width: fit-content;
    padding: 0.5rem;
    font-size: 0.875rem;
    font-weight: 500;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 0.5rem;
    align-items: center;
    color: var(--secondary-color);
    border-radius: 0.25rem;
  }
  .dashBoardGraphWrapper {
    width: 100%;
    margin-top: 1rem;
    border-bottom: 2px solid #dbdbdb;
    padding-bottom: 2rem;

    .dashboardCheckboxWrapper {
      width: 100%;
      display: flex;
      gap: 1rem;
      align-items: center;
      justify-content: center;
      margin-top: 1rem;

      .dashboardCheckbox {
        display: flex;
        justify-content: center;
        gap: 0.25rem;
        align-items: center;
        font-weight: 500;
        /*         padding: 0.25rem 0.5rem;
 */
        border-radius: 0.125rem;

        input[type="checkbox"]:not(:checked),
        input[type="checkbox"]:checked {
          position: absolute;
          left: -9999%;
        }

        input[type="checkbox"] + label {
          padding: 0.25rem 0.5rem;
          text-align: center;
          border-radius: 0.5rem;
          cursor: pointer;
        }
      }
    }
  }
}
