.stockCalculationsWrapper {
  margin-left: 11rem;

  .stockHeaderWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;

    .stockHeader {
      width: 95%;
      text-align: center;
      color: var(--primary-color);
      padding-bottom: 1rem;
      border-bottom: 3px solid rgba($color: #dbdbdb, $alpha: 0.5);
    }
  }

  .calculationCardsWrapper {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
  }
}
.stockCalculationsButtonsWrapper {
  margin-top: 1rem;
  display: flex;
  align-items: center;
  width: 90%;
  padding-left: 2rem;

  button {
    padding: 1em 2em;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    font-size: 0.875rem;
    font-weight: 500;
    color: var(--primary-color);
    background-color: #fff;
    border: none;
    border-radius: 45px;
    box-shadow: 0px 8px 15px rgba(13, 71, 161, 0.1);
    transition: all 0.3s ease 0s;
    cursor: pointer;
    outline: none;
  }

  button:hover {
    background-color: var(--secondary-color);
    box-shadow: 0px 15px 20px rgba(243, 120, 38, 0.4);
    color: #fff;
    transform: translateY(-7px);
  }

  button:active {
    transform: translateY(-1px);
  }
}

.stockCalculationsFiltersWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 25%;

  input[type="checkbox"]:not(:checked),
  input[type="checkbox"]:checked {
    position: absolute;
    left: -9999%;
  }

  input[type="checkbox"] + label {
    border: 1px solid var(--primary-color);
    padding: 0.5rem 1rem;
    margin-left: 1rem;
    text-align: center;
    border-radius: 0.5rem;
    color: var(--primary-color);
    cursor: pointer;
  }
  @keyframes checkboxChecked {
    from {
      background-color: var(--background-color);
      color: var(--primary-color);
    }
    to {
      background-color: var(--primary-color);
      color: var(--background-color);
    }
  }
  input[type="checkbox"]:checked + label {
    background-color: var(--primary-color);
    color: var(--background-color);
    animation-name: checkboxChecked;
    animation-duration: 0.5s;
  }
}
