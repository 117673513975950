@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.AddPopUpBg {
  top: 0;
  width: 100vw;
  left: 0;
  height: 100vh;
  background-color: rgba(219, 219, 219, 0.8);
  position: fixed;
  z-index: 55;
  animation-duration: 1s;
  animation-name: fade-in;
}

.AddPopUpWorkOrderWrapper {
  position: fixed;
  background-color: white;
  border-radius: 0.25rem;
  top: 50%;
  left: 50%;
  height: 90vh;
  width: 80%;
  overflow-x: hidden;
  overflow-y: scroll;
  transform: translate(-50%, -50%);
}

.AddPopUpHeader {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.25rem;
  font-weight: 500;
  background-color: var(--primary-color);
  height: 3rem;
  color: var(--background-color);
}

.AddWorkOrderPopUpContent {
  padding: 1rem;
  width: 100%;
  display: flex;

  .WorkOrderPopUpRightWrapper {
    border-left: 1px solid rgba(219, 219, 219, 0.8);
    width: 50%;
  }

  .WorkOrderPopUpLeftWrapper {
    width: 50%;

    .WorkOrderPopUpDropDown {
      width: 90%;

      margin-bottom: 1rem;
    }
  }

  .WorkOrderProductItemWrapper {
    display: flex;
    flex-wrap: wrap;

    .AddWorkOrderProductItem {
      display: flex;
      justify-content: center;
      align-items: center;
      .WorkOrderPopUpItemName {
        text-align: center;
        margin-right: 0.75rem;
        margin-bottom: 0.25rem;
        padding: 0.5rem 1rem;
        background-color: var(--primary-color);
        color: var(--background-color);
        width: fit-content;
        margin: 0.25rem;
        border-radius: 0.75rem;
        display: flex;
        justify-content: space-around;
        align-items: center;
        svg {
          cursor: pointer;
          margin-right: 0.25rem;
          margin-left: 0.5rem;
        }
      }

      .AddWorkOrderProductAmount {
        background-color: inherit;
        display: flex;
        justify-content: center;
        width: 2rem;
        text-align: center;
        font-size: 1rem;
        color: inherit;
        border: none;
        padding: 0.5rem;
        border: 3px solid var(--primary-color);
        border-radius: 0.5rem;

        input:focus-visible {
          outline: none;
        }
      }
    }
  }

  .WorkOrderSelectWrapper {
    margin-top: 1rem;
    display: flex;
    align-items: center;

    .workOrderSelectHeader {
      font-weight: 500;
      width: 45%;
    }

    .workOrderPopUpDescInfoInput {
      width: 40%;
      margin-left: 1rem;
      border: 1px solid rgba(13, 61, 171, 0.5);
      padding: 0.5rem;
    }

    .workOrderPopUpInfoInput {
      width: 40%;
      margin-left: 1rem;
      padding: 0.25rem;
      border: 1px solid rgba(13, 61, 171, 0.5);
    }

    input[type="checkbox"]:not(:checked),
    input[type="checkbox"]:checked {
      position: absolute;
      left: -9999%;
    }

    input[type="checkbox"] + label {
      padding: 0.25rem 0.5rem;
      border-radius: 0.125rem;
      width: 2.5rem;
      text-align: center;
      margin-left: 2rem;
      background-color: var(--background-color);
      color: var(--primary-color);
      cursor: pointer;
    }

    input[type="checkbox"]:checked + label {
      background-color: var(--primary-color);
      color: var(--background-color);
    }
  }

  .workEntriesWrapper {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .workEntryDescriptionValue {
    width: 90%;
    padding: 0.5rem;
    border: 1px dashed rgba(13, 61, 171, 0.5);
  }
  .workEntryItem {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .workEntryValue {
    border: 1px dashed rgba(13, 61, 171, 0.5);
    text-align: center;
    width: 40%;
  }

  .workEntryHeader {
    width: 100%;
    text-align: center;
    margin: 0.25rem;
  }

  .addWorkOrderBtnWrapper {
    display: flex;
    justify-content: center;
    align-items: center;

    .addWorkOrderBtn {
      background-color: var(--primary-color);
      color: var(--background-color);
      border-radius: 0.125rem;
      width: fit-content;
      padding: 0.5rem;
      border: none;
      margin-top: 0.25rem;
      cursor: pointer;

      &:disabled {
        opacity: 0.4;
        cursor: not-allowed;
      }
    }
  }

  .addedWorkEntryWithIconsWrapper {
    padding: 0.5rem;
    width: 90%;
    border-bottom: 1px solid rgba(219, 219, 219, 0.8);
    display: flex;
    flex-direction: column;
    align-items: center;

    .addedWorkEntryWrapper {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-around;
      text-align: center;

      .addedWorkEntryHeader {
        color: var(--primary-color);
        padding-bottom: 0.25rem;
        border-bottom: 1px solid var(--primary-color);
      }
    }
    .workEntryIconsWrapper {
      width: 20%;
      margin: 0.5rem;
      display: flex;
      justify-content: center;
      align-items: center;
      border-bottom: 1px solid rgba(219, 219, 219, 0.8);
      gap: 2rem;

      svg {
        cursor: pointer;
      }
    }
  }
}

.WorkOrderPopUpHeader {
  font-size: 1.125rem;
  font-weight: 500;
  width: 100%;
  text-align: center;
  color: var(--primary-color);

  margin: 1.5rem 0;
}

.buttonWrapper {
  padding: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;

  .addBtn {
    background-color: var(--primary-color);
    color: var(--background-color);
    border-radius: 0.125rem;
    width: fit-content;
    padding: 0.5rem;
    border: none;
    margin-right: 0.25rem;

    &:disabled {
      opacity: 0.4;
      cursor: not-allowed;
    }
  }

  .cancelBtn {
    background-color: gray;
    color: var(--background-color);
    border-radius: 0.125rem;
    width: fit-content;
    padding: 0.5rem;
    border: none;
    cursor: pointer;
    margin-right: 0.25rem;
  }
}

.pdfWrapper {
  width: 100%;
  display: flex;
  color: black;
  flex-direction: column;
  font-family: "New Courier";
  align-items: center;
  justify-content: center;
  font-size: 0.575rem;
}

.pdfInfoWrapper {
  display: flex;
  width: 100%;
  margin-bottom: 1rem;
}

.pdftableWrapper {
  height: 26rem;
  width: 95%;
  padding-right: 0.5rem;
}

.pdfOrderCardcustomerDetailsHeaderWrapper {
  margin-left: 1rem;
  width: 100%;
  margin-top: 1rem;
  position: absolute;

  .orderCardcustomerDetailsHeader {
    width: 50%;
    font-size: 1.5rem;
    font-weight: 600;
  }
}

.pdfInfoLeftItemsWrapper {
  margin-top: 10rem;
  flex-direction: column;
  display: flex;
  width: fit-content;
  .pdfStatusWrapper {
    width: fit-content;
    background-color: #dbdbdb;
    padding: 0.125rem 0.375rem;
    font-weight: 500;
    margin-left: 1rem;
    margin-top: 0.5rem;
  }

  .pdfInfoLeftItem {
    font-weight: 600;
    margin-left: 1rem;
    display: flex;

    .pdfInfoLeftHeader {
      width: 5rem;
    }
    .pdfInfoLeftValue {
      width: 7rem;
    }
  }
}

.pdfInfoRight {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  width: 100%;
  margin-top: 1rem;
  font-size: 0.575rem;
}
.pdfInfoRightWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
}

.pdfInfoRightItem {
  margin-bottom: 0.125rem;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 2rem;
}
.pdfInfoRightCodeHeader {
  font-weight: 600;
  margin-right: 0.25rem;
}

.pdfInfoRightCodeValue {
  background-color: #f5f5f5;
  width: 5rem;
  height: 1rem;
  text-align: center;
  font-weight: 600;
}

.pdfInfoRightHeader {
  font-weight: 600;
  margin-right: 0.25rem;
}
.pdfInfoRightValue {
  background-color: #d9e1f2;
  width: 5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 1rem;
  font-weight: 600;
}
/* .pdfInfoRightSum {
  margin-bottom: 0.125rem;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 2rem;
  color: red;
  margin-top: 1rem;
}
 */
.pdfInfoRightSumHeader {
  font-weight: 600;
  margin-right: 0.25rem;
  text-align: center;
}
.pdfInfoRightSumValue {
  width: 5rem;
  height: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-weight: 600;
}
.pdftableBorder {
  border-bottom: 0.25rem solid red;
  width: 5%;
  border-collapse: collapse;
}
.pdfthAmount {
  width: 5%;
  border-bottom: 0.25rem solid red;
  border-collapse: collapse;
}
.pdfthDescription {
  width: 80%;
  border-bottom: 0.25rem solid red;
  border-collapse: collapse;
}

.pdftdDescription {
  text-align: start;
}

.pdfBottomInfoWrapper {
  padding-left: 1rem;
  width: 100%;
}

.pdfnoteInfo {
  margin-bottom: 1.5rem;
}

.pdfbillIBAN {
  display: inline-flex;
  width: 10rem;
}
.pdfcalcWrapper {
  display: flex;
  width: 30%;
  justify-content: flex-end;
  margin-left: 3rem;
  align-items: flex-start;
}

.pdfcalcHeader {
  display: inline-flex;
  width: 6rem;
}

.pdfcalculationEnd {
  border-bottom: 2px solid black;
}

.pdfsignatureWrapper {
  margin-top: 1rem;
  margin-left: 4rem;
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.pdfsignature {
  margin-left: 6rem;
  width: 4rem;
  height: 1rem;
  border-bottom: 1px solid red;
  border-right: 1px solid red;
}
