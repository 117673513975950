@keyframes offhoverCalcCard {
  from {
    box-shadow: 0px 5px 6px 0px rgba(13, 71, 161, 0.3);
  }
  to {
    box-shadow: 0px 2px 3px 0px rgba(0, 0, 0, 0.3);
  }
}

@keyframes onhoverCalcCard {
  from {
    box-shadow: 0px 2px 3px 0px rgba(0, 0, 0, 0.3);
  }
  to {
    box-shadow: 0px 5px 6px 0px rgba(13, 71, 161, 0.3);
  }
}

.calculationCardGeneral {
  width: 75%;
  height: 5rem;
  justify-content: flex-start;
  gap: 2rem;
  align-items: center;
  display: flex;
  background-color: #fff;
  color: var(--primary-color);
  border-radius: 0.125rem;
  box-shadow: 0px 2px 3px 0px rgba(0, 0, 0, 0.3);
  animation-name: offhoverCalcCard;
  animation-duration: 1s;
  margin-top: 1rem;

  .calculationCardId {
    font-size: 2rem;
    padding: 0 2rem;
    font-weight: 500;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-right: 1px solid var(--secondary-color);
    min-width: fit-content;
  }
  .calculationCardNameWrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 2rem;
    flex-wrap: nowrap;
    width: 80%;
    text-align: center;

    .calculationCardName {
      font-size: 1rem;
      text-align: center;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 100%;
    }
    .calculationCardNameHeader {
      border-bottom: 1px dashed var(--secondary-color);
      padding-bottom: 0.5rem;
      font-size: 1.125rem;
      text-align: center;
      font-weight: 500;
      margin-bottom: 0.25rem;
    }
  }
}
.cssbuttons_io_button {
  background: var(--primary-color);
  color: white;
  font-family: inherit;
  padding: 0.35em;
  padding-left: 1.2em;
  font-size: 17px;
  font-weight: 500;
  border-radius: 0.25em;
  border: none;
  letter-spacing: 0.05em;
  display: flex;
  align-items: center;
  box-shadow: inset 0 0 1.6em -0.6em var(--primary-color);
  overflow: hidden;
  position: relative;
  height: 2.8em;
  padding-right: 3em;
  cursor: pointer;
}

.cssbuttons_io_button .icon {
  background: var(--secondary-color);
  margin-left: 1em;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 2.2em;
  width: 2.2em;
  border-radius: 0.25em;
  box-shadow: 0.1em 0.1em 0.6em 0.2em var(--primary-color);
  right: 0.3em;
  transition: all 0.3s;
}
.cssbuttons_io_button:hover .icon {
  width: calc(100% - 0.6em);
}

.cssbuttons_io_button .icon svg {
  width: 1.1em;
  transition: transform 0.3s;
  color: var(--background-color);
}

.cssbuttons_io_button:hover .icon svg {
  transform: translateX(0.1em);
}

.cssbuttons_io_button:active .icon {
  transform: scale(0.95);
}

.calculationCardGeneral:hover {
  box-shadow: 0px 5px 6px 0px rgba(13, 71, 161, 0.3);
  animation-name: onhoverOrderCard;
  animation-duration: 1s;
}

.calculationCardItem {
  flex: 1;
  min-width: 0;
  text-align: left;
  overflow: hidden;
}
