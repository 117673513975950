.machinesAllWrapper {
  margin-left: 11rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  .machinesAllHeaderWrapper {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    .machinesAllHeader {
      text-align: center;
      color: var(--primary-color);
      width: 90%;
      padding-bottom: 1.5rem;
      border-bottom: 3px solid #dbdbdb;
    }
  }
}

.searchLoaderWrapper {
  display: flex;
  justify-content: start;
  width: 100%;
  align-items: center;
  gap: 1rem;
}
