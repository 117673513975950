.orderCardButtonsWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}

@keyframes offhoverOrderCard {
  from {
    box-shadow: 0px 5px 6px 0px rgba(13, 71, 161, 0.3);
  }
  to {
    box-shadow: 0px 2px 3px 0px rgba(0, 0, 0, 0.3);
  }
}

@keyframes onhoverOrderCard {
  from {
    box-shadow: 0px 2px 3px 0px rgba(0, 0, 0, 0.3);
  }
  to {
    box-shadow: 0px 5px 6px 0px rgba(13, 71, 161, 0.3);
  }
}
.orderCardWrapper {
  width: 90%;
  height: 5rem;
  padding: 2rem;
  margin-top: 1rem;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: space-around;
  box-shadow: 0px 2px 3px 0px rgba(0, 0, 0, 0.3);
  color: var(--primary-color);
  animation-name: offhoverOrderCard;
  animation-duration: 1s;

  .orderCardLeftWrapper {
    display: flex;
    gap: 3rem;
    align-items: center;
    text-align: center;
    padding-right: 20rem;
    border-right: 2px solid var(--primary-color);
    position: relative;

    .orderCardModalDesc {
      position: absolute;
      text-align: left;
      left: 30%;
      word-break: break-all;
    }
  }

  .orderCardMiddleWrapper {
    display: flex;
    align-items: center;
    gap: 3rem;
    border-right: 2px solid var(--primary-color);
    padding-right: 1rem;

    .orderButtonsWrapper {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      .exportBtn {
        padding: 0.5rem;
        width: 8rem;
        color: #f5f5f5;
        background-color: rgb(143, 2, 2);
        margin: 0.5rem;
        border: none;
        cursor: pointer;
        border-radius: 0.125rem;
        &:disabled {
          opacity: 0.4;
          cursor: not-allowed;
        }
      }
    }
  }

  .orderCardSumValue {
    margin-left: 2.5rem;
  }
}

.deleteBtn {
  background-color: red;
  color: var(--background-color);
  border-radius: 0.125rem;
  width: 8rem;
  padding: 0.5rem;
  border: none;
  margin: 0.5rem;

  cursor: pointer;
}

.orderCardWrapper:hover {
  box-shadow: 0px 5px 6px 0px rgba(13, 71, 161, 0.3);
  animation-name: onhoverOrderCard;
  animation-duration: 1s;
}

/* .orderCardWrapper:focus {
  background-color: var(--primary-color);
  color: var(--background-color);
} */

.orderCardState {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.orderCardStateHeader {
  padding-left: 0.5rem;
  opacity: 0.7;
}

.orderCardIcon {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 0.25rem;
}

.orderCardDesc {
  font-weight: 600;
  font-size: 1.125rem;
}
.orderCardId {
  opacity: 0.7;
}

.orderCardUpdatableCodesWrapper {
  margin-right: 1rem;
}

.orderCardUpdatableItem {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.25rem;
  font-size: 0.825rem;
  font-weight: 500;
}

.orderCardUpdatableCodeHeader {
  width: 3rem;
}

.orderCardUpdatableCodeValue {
  width: 5rem;
  text-align: center;
  border-color: rgba(13, 71, 161, 0.4);
  border-radius: 0.125rem;
}

.orderCardUpdatableHeader {
  width: 9rem;
}

.orderCardUpdatableExpHeader {
  width: 3.5rem;
}
.orderCardUpdatableValue {
  width: max-content;

  input[type="checkbox"]:not(:checked),
  input[type="checkbox"]:checked {
    position: absolute;
    left: -9999%;
    &:disabled {
      opacity: 0.7;
      cursor: not-allowed;
    }
  }

  input[type="checkbox"] + label {
    border: 1px solid var(--primary-color);
    padding: 0.25rem;
    margin-left: 0.25rem;
    text-align: center;
    border-radius: 0.25rem;
    cursor: pointer;
  }
  @keyframes checkboxChecked {
    from {
      background-color: var(--background-color);
      color: var(--primary-color);
    }
    to {
      background-color: var(--primary-color);
      color: var(--background-color);
    }
  }

  input[type="checkbox"]:checked + label {
    background-color: var(--primary-color);
    color: var(--background-color);
    animation-name: checkboxChecked;
    animation-duration: 0.5s;
  }
}

table,
th,
td {
  text-align: center;
  position: relative;
  font-weight: 600;
  font-family: "New Courier";
}
table {
  width: 100%;
}

.pdfWrapper {
  width: 100%;
  display: flex;
  color: black;
  flex-direction: column;
  font-family: "New Courier";
  align-items: center;
  justify-content: center;
  font-size: 0.575rem;
}

.pdfInfoWrapper {
  display: flex;
  width: 100%;
  margin-bottom: 1rem;
}

.pdftableWrapper {
  height: 26rem;
  width: 95%;
  padding-right: 0.5rem;
}

.pdfOrderCardcustomerDetailsHeaderWrapper {
  margin-left: 1rem;
  width: 100%;
  margin-top: 1rem;
  position: absolute;

  .orderCardcustomerDetailsHeader {
    width: 50%;
    font-size: 1.5rem;
    font-weight: 600;
  }
}

.pdfInfoLeftItemsWrapper {
  margin-top: 10rem;
  flex-direction: column;
  display: flex;
  width: fit-content;
  .pdfStatusWrapper {
    width: fit-content;
    background-color: #dbdbdb;
    padding: 0.125rem 0.375rem;
    font-weight: 500;
    margin-left: 1rem;
    margin-top: 0.5rem;
  }

  .pdfInfoLeftItem {
    font-weight: 600;
    margin-left: 1rem;
    display: flex;

    .pdfInfoLeftHeader {
      width: 5rem;
    }
    .pdfInfoLeftValue {
      width: 7rem;
    }
  }
}

.pdfInfoRight {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  width: 100%;
  margin-top: 1rem;
  font-size: 0.575rem;
}
.pdfInfoRightWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
}

.pdfInfoRightItem {
  margin-bottom: 0.125rem;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 2rem;
}
.pdfInfoRightCodeHeader {
  font-weight: 600;
  margin-right: 0.25rem;
}

.pdfInfoRightCodeValue {
  background-color: #f5f5f5;
  width: 5rem;
  height: 1rem;
  text-align: center;
  font-weight: 600;
}

.pdfInfoRightHeader {
  font-weight: 600;
  margin-right: 0.25rem;
}
.pdfInfoRightValue {
  background-color: #d9e1f2;
  width: 5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 1rem;
  font-weight: 600;
}
/* .pdfInfoRightSum {
  margin-bottom: 0.125rem;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 2rem;
  color: red;
  margin-top: 1rem;
}
 */
.pdfInfoRightSumHeader {
  font-weight: 600;
  margin-right: 0.25rem;
  text-align: center;
}
.pdfInfoRightSumValue {
  width: 5rem;
  height: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-weight: 600;
}
.pdftableBorder {
  border-bottom: 0.25rem solid red;
  width: 5%;
  border-collapse: collapse;
}
.pdfthAmount {
  width: 5%;
  border-bottom: 0.25rem solid red;
  border-collapse: collapse;
}
.pdfthDescription {
  width: 80%;
  border-bottom: 0.25rem solid red;
  border-collapse: collapse;
}

.pdftdDescription {
  text-align: start;
}

.pdfBottomInfoWrapper {
  padding-left: 1rem;
  width: 100%;
}

.pdfnoteInfo {
  margin-bottom: 1.5rem;
}

.pdfbillIBAN {
  display: inline-flex;
  width: 10rem;
}
.pdfcalcWrapper {
  display: flex;
  width: 30%;
  justify-content: flex-end;
  margin-left: 3rem;
  align-items: flex-start;
}

.pdfcalcHeader {
  display: inline-flex;
  width: 6rem;
}

.pdfcalculationEnd {
  border-bottom: 2px solid black;
}

.pdfsignatureWrapper {
  margin-top: 1rem;
  margin-left: 4rem;
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.pdfsignature {
  margin-left: 6rem;
  width: 4rem;
  height: 1rem;
  border-bottom: 1px solid red;
  border-right: 1px solid red;
}

.orderCardDatePickerWrapper {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1.5rem;
  border-bottom: 1px dashed var(--primary-color);
  border-top: 1px dashed var(--primary-color);

  .orderCardCloseItem {
    text-align: center;
    padding-bottom: 1rem;
    padding-top: 0.25rem;

    h4 {
      border-bottom: 1px solid var(--secondary-color);
      padding-bottom: 0.25rem;
    }

    input {
      padding: 0.5rem;
    }
  }
}
