.stockAllTableWrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  table {
    width: 80%;
  }
}
.group {
  display: flex;
  line-height: 28px;
  align-items: center;
  position: relative;
  max-width: 30%;
  margin-bottom: 1rem;
  margin-left: 2rem;
}

.input {
  height: 40px;
  line-height: 28px;
  padding: 0 1rem;
  width: 100%;
  padding-left: 2.5rem;
  outline: none;
  background: var(--primary-color);
  color: #fff;
  transition: 0.3s ease;
  border: 2px solid;
  border-image: conic-gradient(#00f260, #0575e6, #64f38c) 1;
}

.input::placeholder {
  color: #fff;
}

.input:focus::placeholder {
  color: #999;
}

.searchIcon {
  position: absolute;
  left: 1rem;
  width: 1rem;
  height: 1rem;
}

.productCodeTD {
  display: flex;
  align-items: center;
  justify-content: start;
  gap: 1rem;
  position: relative;
}

.truncatedTD {
  width: 15rem;
  white-space: nowrap;
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
}

.toolTipStockAll {
  color: black;
  z-index: 5;
  white-space: normal;
  word-break: break-word;
  width: 15rem;
}
.searchLoaderWrapperStock {
  display: flex;
  justify-content: start;
  width: 100%;
  align-items: start;
  gap: 1rem;
}

.warningButton {
  background-color: var(--primary-color);
  color: var(--background-color);
  font-weight: 500;
  font-size: 0.875rem;
  padding: 0.5rem 1rem;
  border-radius: 0.25rem;
  cursor: pointer;
  margin-top: 0.25rem;
}

.inActiveWarningButton {
  background-color: var(--background-color);
  color: var(--primary-color);
  border: 1px solid var(--primary-color);
  font-weight: 500;
  font-size: 0.875rem;
  padding: 0.5rem 1rem;
  border-radius: 0.25rem;
  cursor: pointer;
  margin-top: 0.25rem;
}
