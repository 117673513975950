.AddPopUpEditProductWrapper {
  position: fixed;
  background-color: white;
  border-radius: 0.25rem;
  top: 50%;
  left: 50%;
  width: 95%;
  height: fit-content;
  transform: translate(-50%, -50%);
  z-index: 1000;
}

.optionalsProductWrapper {
  display: flex;
  width: 100%;
  padding: 1rem;
  justify-content: center;
  align-items: center;
  font-weight: 500;

  .ProductInput {
    margin: 1rem;
    padding: 0.375rem;
    border: solid 1px var(--background-color);
    background-color: var(--background-color);
  }
}

.AddPopUpEditProductContent {
  display: flex;
  margin-top: 1rem;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  font-weight: 500;

  .ProductInput {
    padding: 0.375rem;
    border: solid 1px var(--background-color);
    background-color: var(--background-color);
  }
}

.EditProductInfoWrapper {
  display: flex;
  justify-content: center;

  width: 100%;
  .EditProductInfo {
    font-size: 1.125rem;
    font-weight: 500;
    margin: 0 1rem 1rem 1rem;
    border-bottom: 1px solid #dbdbdb;
  }
}
