.workOrderAllWrapper {
  margin-left: 11rem;
  .workOrderAllHeaderWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;

    .workOrderAllHeader {
      width: 95%;
      text-align: center;
      color: var(--primary-color);
      padding-bottom: 1rem;
      border-bottom: 3px solid rgba($color: #dbdbdb, $alpha: 0.5);
    }
  }

  .searchLoaderWrapper {
    display: flex;
    justify-content: start;
    width: 100%;
    align-items: center;
    gap: 1rem;
  }

  .workOrderAllTableWrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .fl-table {
      width: 95%;

      .workorderAllTableDesc {
        width: 20%;
        white-space: normal;
        word-break: break-word;
      }
      .workorderAllTableCustomer {
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .exportBtn {
        padding: 0.5rem;
        width: fit-content;
        color: #f5f5f5;
        background-color: rgb(143, 2, 2);
        border: none;
        cursor: pointer;
        border-radius: 0.125rem;
        &:disabled {
          opacity: 0.4;
          cursor: not-allowed;
        }
      }
    }
  }

  .cssbuttons-io-button {
    background: var(--primary-color);
    color: white;
    font-family: inherit;
    padding: 0.35em;
    padding-left: 1.2em;
    font-size: 17px;
    font-weight: 500;
    border-radius: 0.25em;
    border: none;
    letter-spacing: 0.05em;
    display: flex;
    align-items: center;
    box-shadow: inset 0 0 1.6em -0.6em var(--primary-color);
    overflow: hidden;
    position: relative;
    height: 2.8em;
    padding-right: 3em;
    cursor: pointer;
  }

  .cssbuttons-io-button .icon {
    background: var(--secondary-color);
    margin-left: 1em;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 2.2em;
    width: 2.2em;
    border-radius: 0.25em;
    box-shadow: 0.1em 0.1em 0.6em 0.2em var(--primary-color);
    right: 0.3em;
    transition: all 0.3s;
  }
  .cssbuttons-io-button:hover .icon {
    width: calc(100% - 0.6em);
  }

  .cssbuttons-io-button .icon svg {
    width: 1.1em;
    transition: transform 0.3s;
    color: var(--background-color);
  }

  .cssbuttons-io-button:hover .icon svg {
    transform: translateX(0.1em);
  }

  .cssbuttons-io-button:active .icon {
    transform: scale(0.95);
  }
  .orderCardUpdatablesWrapper {
    display: flex;
    align-items: center;
  }
}

.loadMoreButton {
  background-color: var(--primary-color);
  color: var(--background-color);
  font-weight: 500;
  font-size: 0.875rem;
  padding: 0.5rem 1rem;
  border-radius: 0.25rem;
  margin: 2rem;
  cursor: pointer;
}
