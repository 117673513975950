.workOrderPage {
  margin-left: 10rem;
}
.LoaderWrapper {
  top: 0;
  width: 100vw;
  left: 0;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(219, 219, 219, 0.8);
  position: absolute;
  z-index: 5;
}
.LoaderWrapper {
  top: 0;
  width: 100vw;
  left: 0;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(219, 219, 219, 0.8);
  position: absolute;
  z-index: 5;
}

.workOrderTabs {
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 1rem 0rem;

  input[type="checkbox"]:not(:checked),
  input[type="checkbox"]:checked {
    position: absolute;
    left: -9999%;
  }

  input[type="checkbox"] + label {
    width: 45%;
    text-align: center;
    font-size: 1.25rem;
    font-weight: 600;
    cursor: pointer;

    padding-bottom: 1rem;
    opacity: 0.5;
  }
  @keyframes checkboxTabChecked {
    from {
      opacity: 0.5;
      border-bottom: solid 3px var(--background-color);
    }
    to {
      border-bottom: solid 3px var(--primary-color);
      opacity: 1;
    }
  }
  input[type="checkbox"]:checked + label {
    border-bottom: solid 3px var(--primary-color);
    opacity: 1;
    animation-name: checkboxTabChecked;
    animation-duration: 1s;
  }
}
